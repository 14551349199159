import { createStore } from 'vuex';

export default createStore({
  state: {
    userData: {
      token: null,
      banners: {
        program: null,
        partner: null,
        participant: null,
        speaker: null,
      },
      inbox: {
        notification: 0,
        messages: 0,
        unreadChat: 0,
      },
    },
    // @domain {String} - адрес API сервера
    // @exhId {Number} - ID мероприятия выбранного пользователем
    axios: {
      // domain: 'https://dev.expoup.ru',
      domain: 'https://plusforum.expoup.ru',
      version: 'v1',
      lang: 'ru',
      // exId: '3',
      exId: null,
    },
    lastUrl: null,
    // Что это?
    infoMessage: {
      type: null,
      text: null,
    },
    navigations: {
      showBtnToEventList: true,
      showBtnAsideMenu: true,
      showBottomNav: true,
    },
    mainTitle: {},
    pageTitle: null,
    system: {
      timer_update_ts: null,
      updating_id: null,
      progressVal: 0,
    },
  },
  getters: {
    getExhId(state) {
      if (state.axios.exId === null) {
        const setId = localStorage.getItem('exhID') || null;
        state.axios.exId = setId;
      }
      return state.axios.exId;
    },
    getLang(state) {
      return state.axios.lang;
    },
    // eslint-disable-next-line no-unused-vars
    apiPath(state) {
      return `${state.axios.domain}/wapi/${state.axios.version}`;
    },
    // eslint-disable-next-line no-unused-vars
    apiBasePath(state, getters) {
      return `${getters.apiPath}/${state.axios.lang}/${state.axios.exId}`;
    },
    apiProgramsPath(state, getters) {
      return `${getters.apiBasePath}/program`;
    },
    apiStaticPage(state, getters) {
      return `${getters.apiBasePath}/....`;
    },
    isAuth(state) {
      return !!state.userData.token;
    },
    getToken(state) {
      return state.userData.token;
    },
    getLastUrl(state) {
      return state.lastUrl;
    },
    getNewNotificationCount(state) {
      return state.userData.inbox.notification;
    },
    getUnreadChatCount(state) {
      return state.userData.inbox.unreadChat;
    },
    getMainTitle(state, getters) {
      return state.mainTitle[getters.getLang];
      // return state.mainTitle;
    },
    getPageTitle(state) {
      return state.pageTitle;
    },
    // Что это?
    getInfoMessages(state) {
      if (state.infoMessage.text) {
        return { type: state.infoMessage.type, text: state.infoMessage.text };
      }
      return null;
    },
    // ===Banner===
    getBannerProgramTs(state) {
      return state.userData.banners.program;
    },
    getBannerPartnerTs(state) {
      return state.userData.banners.partner;
    },
    getBannerParticipantTs(state) {
      return state.userData.banners.participant;
    },
    getBannerSpeakerTs(state) {
      return state.userData.banners.speaker;
    },
    // =/=Banner=/=
    // ===System===
    getTimerUpdate(state) {
      return state.system.timer_update_ts;
    },
    getUpdatingID(state) {
      return state.system.updating_id;
    },
    isProgressVal(state) {
      return state.system.progressVal;
    },
    // =/=System=/=
    // ===Navigations===
    isVisibleBtnMenu(state) {
      return state.navigations.showBtnAsideMenu;
    },
    isVisibleBtnToEventList(state) {
      return state.navigations.showBtnToEventList;
    },
    isVisiblBottomNav(state) {
      return state.navigations.showBottomNav;
    },
    // =/=Navigations=/=
  },
  mutations: {
    setExhId(state, value) {
      localStorage.setItem('exhID', value);
      state.axios.exId = value;
    },
    setLang(state, value) {
      state.axios.lang = value;
    },
    setToken(state, value) {
      state.userData.token = value;
    },
    setLastUrl(state, { name, params }) {
      state.lastUrl = { name, params };
    },
    setNewNotificationCount(state, value) {
      state.userData.inbox.notification = value > 0 ? value : 0;
    },
    setUnreadChatCount(state, value) {
      state.userData.inbox.unreadChat = value > 0 ? value : 0;
    },
    setMainTitle(state, value) {
      state.mainTitle[value.lang] = value.text;
      // state.mainTitle = value;
    },
    setPageTitle(state, value) {
      state.pageTitle = value;
    },
    // ===Banner===
    setBannerProgramTs(state, value) {
      state.userData.banners.program = value;
    },
    setBannerPartnerTs(state, value) {
      state.userData.banners.partner = value;
    },
    setBannerParticipantTs(state, value) {
      state.userData.banners.participant = value;
    },
    setBannerSpeakerTs(state, value) {
      state.userData.banners.speaker = value;
    },
    // =/=Banner=/=
    // ===System===
    setTimerUpdate(state, value) {
      state.system.timer_update_ts = value;
    },
    setUpdatingID(state, value) {
      state.system.updating_id = value;
    },
    setProgressVal(state, value) {
      state.system.progressVal = value;
    },
    // =/=System=/=
    // ===set Navigations===
    visibleBtnMenu(state, value) {
      state.navigations.showBtnAsideMenu = value;
    },
    visibleBtnToEventList(state, value) {
      state.navigations.showBtnToEventList = value;
    },
    visiblBottomNav(state, value) {
      state.navigations.showBottomNav = value;
    },
    // =/=set Navigations=/=
  },
  actions: {
  },
  modules: {
  },
});
